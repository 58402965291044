<ng-container *ngIf="stripeClientSecret$ | async"></ng-container>
<ng-container *ngIf="checkoutModalService.refreshCompanyFields$ | async"></ng-container>
<ng-container *ngIf="checkoutModalService.updateMissingFields$ | async"></ng-container>

<div class="checkout" [ngClass]="{ 'mb-24': (responsiveService.isMobile | async) }">
  <h4 [ngClass]="(responsiveService.isMobile | async) ? 'mb-16' : 'mb-40'">
    Complétez vos informations de facturation
  </h4>
  @if (checkoutModalService.missingFields.length > 0) {
    <div class="missing-fields" [ngClass]="(responsiveService.isMobile | async) ? 'mb-16' : 'mb-40'">
      <form [formGroup]="checkoutModalService.formGroup">
        <ng-container *ngIf="checkoutModalService.formGroupChanged$ | async"></ng-container>
        @for (missingField of checkoutModalService.missingFields; track missingField.field) {
          <div
            [ngClass]="{
              'half-field': missingField.field === 'headquarter.zipcode' || missingField.field === 'headquarter.city'
            }"
            class="form-layout mb-8"
          >
            <dougs-field
              [formControlName]="missingField.field"
              [field]="missingField"
              [data-cy]="missingField.field"
              [showMissingBadge]="false"
            />
          </div>
        }
      </form>
    </div>
  }

  <h5 dougsFormFieldLabel class="mb-8"><span class="color-gray small checkout__stripe-title">Carte bancaire</span></h5>
  @if (checkoutModalService.isSecretLoaded$ | async) {
    <ngx-stripe-card
      [options]="checkoutModalService.cardOptions"
      [elementsOptions]="{ clientSecret: checkoutModalService.stripeClientSecret, locale: 'fr' }"
    />
  }
  @if (!(checkoutModalService.isSecretLoaded$ | async)) {
    <dougs-loader class="my-24" />
  }
  <p class="xtiny mt-8 color-gray">
    En transmettant mes données de paiement, j'autorise la société Dougs SAS à envoyer des instructions financières à
    l'établissement dont est issue ma carte bancaire afin de prélever les paiements dus, en accord avec la lettre de
    mission et les conditions générales de vente.
  </p>
</div>
