import { Inject, Injectable, Type } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SetupIntent, StripeCardElementOptions, StripeError } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { BehaviorSubject, concatMap, from, lastValueFrom, map, Observable, take, tap, withLatestFrom } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ConfigBackService } from '@dougs/core/config-back';
import { mergeObjects } from '@dougs/core/utils';
import { FlashMessagesService, MODAL_DATA, ModalRef } from '@dougs/ds';
import { Field, Fields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { CheckoutOptions } from '@dougs/services/dto';
import { ServicesStateService } from '@dougs/services/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class CheckoutModalService {
  cardOptions: StripeCardElementOptions = {
    classes: {
      base: 'form-field-control',
      invalid: 'ng-invalid ng-touched',
      focus: 'has-focus',
    },
    hidePostalCode: true,
    style: {
      base: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '16px',
        padding: '4',
        color: this.userStateService.hasDarkModeEnabled() ? '#fff' : '#000',
      },
    },
    preferredNetwork: ['cartes_bancaires'],
  };

  missingFields: Field[] = [];
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  stripeClientSecret!: string | null;

  private readonly isLoadingPaymentSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoadingPayment$: Observable<boolean> = this.isLoadingPaymentSubject.asObservable();

  private readonly isSecretLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSecretLoaded$: Observable<boolean> = this.isSecretLoadedSubject.asObservable();

  refreshCompanyFields$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((company) => this.fieldsStateService.refreshCompanyFields(company)),
  );

  updateMissingFields$: Observable<Fields | void> = this.fieldsStateService.missingFields$.pipe(
    withLatestFrom(this.configBackService.legalForms$),
    map(([missingFields]) => {
      this.filterEffectiveMissingFields(missingFields);
      this.sortMissingFields();
      this.updateFormGroupWithMissingFields();
      return missingFields;
    }),
  );

  formGroupChanged$: Observable<void> = this.formGroup.valueChanges.pipe(
    tap(() => this.updateCompanyModel()),
    concatMap(() => from(this.fieldsStateService.refreshCompanyFields(this.data.company))),
  );

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      company: Company;
      options: CheckoutOptions;
      checkoutInformations?: Type<unknown>;
    },
    private readonly stripeService: StripeService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly modalRef: ModalRef,
    private readonly userStateService: UserStateService,
    private readonly fieldsStateService: FieldsStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly servicesStateService: ServicesStateService,
    private readonly configBackService: ConfigBackService,
  ) {}

  async setStripeClientSecret(): Promise<void> {
    this.stripeClientSecret = await this.servicesStateService.getStripeClientSecret(this.data.company);
    this.isSecretLoadedSubject.next(true);
  }

  async pay(stripeCard: StripeCardComponent): Promise<void> {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      await this.companyStateService.updateCompany(this.data.company);
    }

    if (this.stripeClientSecret) {
      this.isLoadingPaymentSubject.next(true);
      const stripeConfirm: {
        setupIntent?: SetupIntent;
        error?: StripeError;
      } = await lastValueFrom(
        this.stripeService
          .confirmCardSetup(this.stripeClientSecret, {
            payment_method: {
              card: stripeCard.element,
              billing_details: {
                name: this.data.company.id.toString() || '',
              },
            },
          })
          .pipe(take(1)),
      );

      if (stripeConfirm.error) {
        this.flashMessagesService.show(
          stripeConfirm.error.message ||
            'Nous ne parvenons pas à authentifier votre méthode de paiement. Veuillez choisir une autre méthode de paiement et réessayez.',
          {
            type: 'error',
            timeout: 20000,
            canClose: true,
          },
        );
      } else {
        this.modalRef.close(this.stripeClientSecret);
      }
      this.isLoadingPaymentSubject.next(false);
    }
  }

  trackByField(index: number, item: Field): string {
    return item.field;
  }

  private updateCompanyModel(): void {
    this.data.company = mergeObjects(
      this.companyStateService.activeCompany,
      this.formatFormGroupValueWithNestedAttributes(),
    );
  }

  private updateFormGroupWithMissingFields(): void {
    for (const missingField of this.missingFields) {
      if (!this.formGroup.get(missingField.field)?.dirty) {
        this.formGroup.addControl(missingField.field, new UntypedFormControl(null, [Validators.required]), {
          emitEvent: false,
        });
      }
    }
  }

  private formatFormGroupValueWithNestedAttributes(): Record<string, unknown> {
    return Object.keys(this.formGroup.value).reduce(
      (formattedAccValue: Record<string, unknown>, currentFormControlKey: string) => {
        const isNested: boolean = currentFormControlKey.includes('.');
        if (!isNested) {
          formattedAccValue[currentFormControlKey] = this.formGroup.value[currentFormControlKey];
          return formattedAccValue;
        }
        const [nestedKey, attributeKey]: string[] = currentFormControlKey.split('.');
        formattedAccValue[nestedKey] = formattedAccValue[nestedKey] || {};
        const nestedFormGroup: Record<string, unknown> = formattedAccValue[nestedKey] as Record<string, unknown>;
        nestedFormGroup[attributeKey] = this.formGroup.value[currentFormControlKey];
        return formattedAccValue;
      },
      {},
    );
  }

  private sortMissingFields(): void {
    const indexLegalName: number = this.missingFields.findIndex((f) => f.field === 'legalName');
    let indexLegalForm: number = this.missingFields.findIndex((f) => f.field === 'legalForm');
    if (indexLegalName >= 0 && indexLegalForm >= 0) {
      const [fieldLegalName] = this.missingFields.splice(indexLegalName, 1);
      indexLegalForm = this.missingFields.findIndex((f) => f.field === 'legalForm');
      this.missingFields.splice(indexLegalForm + 1, 0, fieldLegalName);
    }
  }

  private filterEffectiveMissingFields(missingFields: Fields): void {
    const effectiveMissingFieldsKeys: string[] = Object.keys(missingFields).filter(
      (key) => missingFields[key].groupIds?.includes('payment') && !this.missingFields.find((f) => f.field === key),
    );
    this.missingFields = [...this.missingFields, ...effectiveMissingFieldsKeys.map((key) => missingFields[key])];
  }
}
