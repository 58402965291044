<ng-container *ngIf="checkoutModalService.refreshCompanyFields$ | async"></ng-container>
<ng-container *ngIf="checkoutModalService.updateMissingFields$ | async"></ng-container>

<div class="checkout-modal">
  <div class="checkout-modal__header py-16 px-32">
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div
    dougsModalContent
    [dougsModalContent]="checkoutModalService.data.checkoutInformations ? 'large' : 'medium'"
    class="checkout-modal__content mb-24"
  >
    <dougs-checkout [ngClass]="{ split: checkoutModalService.data.checkoutInformations }" />
    <ng-container
      *ngComponentOutlet="
        checkoutModalService.data.checkoutInformations;
        inputs: { options: checkoutModalService.data.options }
      "
    />
  </div>

  <div dougsModalFooter class="checkout-modal__footer">
    <dougs-button color="secondary" [fullWidth]="responsiveService.isMobile | async" dougsModalClose
      >Annuler</dougs-button
    >
    <dougs-button
      type="button"
      (click)="checkoutModalService.pay(checkout.stripeCard)"
      [disabled]="!checkoutModalService.formGroup.valid"
      [fullWidth]="responsiveService.isMobile | async"
      [ngClass]="{ 'mb-16': responsiveService.isMobile | async }"
      data-cy="checkout-modal-pay-button"
    >
      <i class="fal fa-lock mr-8"></i>
      {{
        checkoutModalService.data.options?.buttonText
          ? checkoutModalService.data.options.buttonText
          : 'Payer ' + (checkoutModalService.data.options.amount | currency: 'EUR') + ' TTC'
      }}
    </dougs-button>
  </div>
</div>
<dougs-loader-fullpage *ngIf="checkoutModalService.isLoadingPayment$ | async">
  {{ checkoutModalService.data?.options?.loadingText ?? 'Paiement en cours...' }}
</dougs-loader-fullpage>
