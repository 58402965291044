import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResponsiveService } from '@dougs/core/responsive';
import {
  ButtonComponent,
  LoaderComponent,
  LoaderFullpageComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { FieldComponent } from '@dougs/fields/ui';
import { CheckoutModalService } from '../../services/checkout-modal.service';
import { CheckoutComponent } from './checkout/checkout.component';

@Component({
  selector: 'dougs-checkout-modal',
  templateUrl: './checkout-modal.component.html',
  styleUrls: ['./checkout-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CheckoutModalService],
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalFooterDirective,
    ButtonComponent,
    LoaderComponent,
    LoaderFullpageComponent,
    FieldComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    CheckoutComponent,
  ],
})
export class CheckoutModalComponent {
  @ViewChild(CheckoutComponent) checkout!: CheckoutComponent;

  constructor(
    public readonly checkoutModalService: CheckoutModalService,
    public readonly responsiveService: ResponsiveService,
  ) {}
}
