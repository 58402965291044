import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { NgxStripeModule, StripeCardComponent } from 'ngx-stripe';
import { asyncScheduler, scheduled } from 'rxjs';
import { ResponsiveService } from '@dougs/core/responsive';
import { ButtonComponent, DividerComponent, LabelFormFieldDirective } from '@dougs/ds';
import { FieldComponent } from '@dougs/fields/ui';
import { CheckoutModalService } from '../../../services/checkout-modal.service';

@Component({
  selector: 'dougs-checkout',
  standalone: true,
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  imports: [
    CommonModule,
    DividerComponent,
    ButtonComponent,
    NgxStripeModule,
    FieldComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    LabelFormFieldDirective,
  ],
})
export class CheckoutComponent {
  @ViewChild(StripeCardComponent) stripeCard!: StripeCardComponent;

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
  };

  stripeClientSecret$ = scheduled(this.checkoutModalService.setStripeClientSecret(), asyncScheduler);

  constructor(
    public readonly checkoutModalService: CheckoutModalService,
    public readonly responsiveService: ResponsiveService,
  ) {}
}
